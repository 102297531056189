import { MobileIcon } from '@pidk/common/src/components/Icons'
import { darken } from 'polished'
import { useState } from 'react'
import styled from 'styled-components'

import useRendererContext from '../../hooks/useRendererContext'
import useSocket from '../../hooks/useSocket'
import { getProjectThemeColor } from '../../utils/project'
import RoomModal from './RoomModal'

const RoomInfo = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-family: ${props => props.theme.fonts.fontFamily.body};
  font-size: 1.0rem;
  cursor: pointer;
  padding: 0 1rem 0 1rem;

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${({ theme }) => darken(0.15, getProjectThemeColor(theme.colors, theme.nav.backgroundColor))}
  }
`

const NavBtn = styled.button`
  height: 100%;
  padding: 0 0.5rem;
  background: none;
  border: 0;
  width: 112px;
  cursor: pointer;
  font-weight: bold;
  color: ${({ theme }) => getProjectThemeColor(theme.colors, theme.nav.color)};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${({ theme }) => darken(0.15, getProjectThemeColor(theme.colors, theme.nav.backgroundColor))}
  }

  &:last-child {
    margin-right: 0;
  }

  &:disabled {
    opacity: 0.2;
    cursor: not-allowed;
  }
`

interface IRoomControls {
  showRoomModal: boolean
}

const RoomControls = ({ showRoomModal }: IRoomControls) => {
  const [showModal, setShowModal] = useState<boolean>(showRoomModal)
  const {
    onRoomActivate,
    onRoomDeactivate,
    isEditable
  } = useRendererContext()
  const socketContext = useSocket()

  if (isEditable) {
    return null
  }

  const handleCloseModal = () => {
    setShowModal(false)
  }

  const handleRoomDeactivate = async () => {
    const code = sessionStorage.getItem('code')
    await onRoomDeactivate(socketContext.state.socket.id, code)
    setShowModal(false)
  }

  const handleInfoClick = async () => {
    setShowModal(true)
  }

  const handleRoomActivate = async () => {
    await onRoomActivate(socketContext.state.socket.id, sessionStorage.getItem('code'))
    setShowModal(true)
  }

  return (
    <>
      {showModal && (
        <RoomModal
          onClose={handleCloseModal}
          onDeactivateRoom={handleRoomDeactivate}
        />
      )}

      {socketContext.state.room?.status === 'OPEN' && (
        <RoomInfo onClick={handleInfoClick}>
          <MobileIcon
            width={32}
            height={32}
          />
          <span>
            {socketContext.state.room.code}
          </span>
        </RoomInfo>
      )}

      {socketContext.state.room?.status === 'CLOSED' && (
        <NavBtn onClick={handleRoomActivate}>
          Stemmen activeren
        </NavBtn>
      )}
    </>
  )
}

export default RoomControls
