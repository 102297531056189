import { getCurrentDeck } from '@pidk/renderer/src/utils/project'
import { useEffect } from 'react'
import { useUpdateEffect } from 'react-use'

import useRendererContext from '../../hooks/useRendererContext'
import useSocket from '../../hooks/useSocket'
import useSocketInitialize from '../../hooks/useSocketInitialize'

const SocketHandler = () => {
  const {
    project,
    rendererState,
    socketUrl,
    onError,
    onRoomCreate,
    setRendererState,
    currentDeckId
  } = useRendererContext()
  const currentDeck = getCurrentDeck(project, currentDeckId)

  const initSocket = useSocketInitialize({
    socketUrl,
    onError,
    onReconnect: onRoomCreate,
    projectId: project.id,
    isHost: true
  })

  const socketContext = useSocket()

  // Triggered once "lesson start"
  useUpdateEffect(() => {
    if (socketContext.state.isConnected) {
      return
    }

    if (!rendererState.hasStarted) {
      return
    }

    if (!currentDeck.hasVoting) {
      return
    }

    if (sessionStorage.getItem('code')) {
      // we already have a code, don't create a new one
      return
    }

    (async () => {
      const socketId = await initSocket()
      const roomCode = await onRoomCreate(socketId, project.id)
      sessionStorage.setItem('code', roomCode)
    })()
  }, [
    rendererState.hasStarted,
    currentDeck?.hasVoting,
    socketContext.state.isConnected
  ])

  useUpdateEffect(() => {
    if (socketContext.state.room?.status === 'OPEN') {
      setRendererState(prevState => ({
        ...prevState,
        isConnected: true
      }))
    } else {
      setRendererState(prevState => ({
        ...prevState,
        isConnected: false
      }))
    }
  }, [socketContext.state.room?.status])

  // Recover host session
  useEffect(() => {
    (async () => {
      const code = sessionStorage.getItem('code')

      if (!code) {
        socketContext.setState(prevState => ({
          ...prevState,
          isInitialized: true
        }))

        return
      }

      const socketId = await initSocket()
      await onRoomCreate(socketId, project.id, code)

      socketContext.setState(prevState => ({
        ...prevState,
        isInitialized: true
      }))
    })()
  }, [])

  return null
}

export default SocketHandler
