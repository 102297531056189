import { EventType } from '@pidk/api/src/types/api'
import type { Project } from '@pidk/compose/src/types/compose'
import { getDeckStartedFromStorage, setDeckStartedInStorage } from '@pidk/renderer/src/utils/event'
import { getIsCheckingOut, setIsCheckingOut } from '@pidk/web/src/utils/checkout'
import { useEffect, useRef, useState } from 'react'
import { useFullscreen, useToggle, useUpdateEffect } from 'react-use'
import styled from 'styled-components'

import useRendererContext from '../../hooks/useRendererContext'
import useSlidePosition from '../../hooks/useSlidePosition'
import { getCurrentDeck, getCurrentSlide } from '../../utils/project'
import { getSlideTypeComponent } from '../../utils/slide'
import { getViewModeFromStorage, setViewModeInStorage } from '../../utils/viewMode'
import ModalStart from '../Overview/ModalStart'
import Nav from './../Nav/Nav'

const Placeholder = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  font-family: ${props => props.theme.fonts.fontFamily.heading};
`

interface SlideProps {
  project: Project
  currentDeckId: string
  currentSlideId: string
  isEditable: boolean
  selectedAreaId?: string
  onAreaClick: (id: string) => void
}

const Slide = ({
  project,
  currentDeckId,
  currentSlideId,
  isEditable,
  onAreaClick,
  selectedAreaId
}: SlideProps) => {
  const deckRef = useRef(null)
  const currentDeck = getCurrentDeck(project, currentDeckId)
  const currentSlide = getCurrentSlide(project, currentDeckId, currentSlideId)
  const { hasNext, currentIndex } = useSlidePosition()
  const { onScopedEventCreate, rendererState, setRendererState, isPreview } = useRendererContext()
  const [showModalStart, setShowModalStart] = useState<boolean>(false)//!rendererState.hasAskedForStartLesson && !isPreview
  const [isFullScreen, setIsFullScreen] = useToggle(false)
  const viewMode = getViewModeFromStorage()

  useFullscreen(deckRef, isFullScreen, { onClose: () => setIsFullScreen(false) })

  const hideModalStart = (viewMode: string) => {
    setShowModalStart(false)
    setViewModeInStorage(viewMode)
  }

  const sendFinishEvent = async () => {
    setRendererState(prevState => ({
      ...prevState,
      hasFinished: true
    }))

    await onScopedEventCreate({
      type: EventType.DECK_FINISH,
      deckId: currentDeckId,
      meta: { interactive: rendererState.isConnected }
    })
  }

  // If a lesson is started setIsFullScreen fullscreen modus, also check it everytime navigate between slides
  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      if (rendererState.hasStarted) {
        setIsFullScreen(true)
      }
    }
  }, [rendererState.hasStarted, currentSlideId])

  const closeSession = async () => {
    const startedDeck = getDeckStartedFromStorage()

    if (startedDeck.deckId !== currentDeckId) {
      return
    }

    setRendererState(prevState => ({
      ...prevState,
      checkoutCallback: undefined
    }))

    // event should already be sent if there's an endingSlide set
    if (!currentDeck.endingSlide) {
      await sendFinishEvent()
    }

    setIsCheckingOut(false)
  }

  // useUpdateEffect prevents the function from running on the first render
  useUpdateEffect(() => {
    (async () => {
      if (isEditable) {
        return
      }

      if (!rendererState.hasStarted) {
        return
      }

      if (currentDeck.endingSlide?.slide === currentSlideId) {
        await sendFinishEvent()
      }

      if (hasNext) {
        return
      }

      /*
        * Ugly hack to make sure the last slide is finished rendering before closing the session.
        * But it seems to be working.
        * It sometimes happens that the isCheckingOut is set to false before the last slide is finished rendering and thus before it's set to true.
      */
      setTimeout(async () => {
        const isCheckingOut = getIsCheckingOut()

        if (isCheckingOut) {
          setRendererState(prevState => ({
            ...prevState,
            checkoutCallback: closeSession
          }))
        } else {
          await closeSession()
        }
      }, 1000)
    })()
  }, [currentIndex, hasNext])

  // Landing and navigating between decks submit the appropiate events
  useEffect(() => {
    (async () => {

      // eslint-disable-next-line no-console
      console.log('switching to deck: ' + currentDeck.id + ' with viewMode: ' + viewMode + ' and hasAskedForStartLesson: ' + rendererState.hasAskedForStartLesson)

      const switchedDeckId = currentDeck.id

      if (viewMode && rendererState.hasAskedForStartLesson) {

        await onScopedEventCreate({
          type: (viewMode === 'start' ? EventType.DECK_START : EventType.DECK_PREVIEW),
          deckId: switchedDeckId
        })

        if (viewMode === 'start') {
          setDeckStartedInStorage(switchedDeckId)
        }
      }

      // Reset the state when switching between decks
      setRendererState(prevState => ({
        ...prevState,
        hasFinished: false
      }))

    })()
  }, [currentDeck.id, viewMode, rendererState.hasAskedForStartLesson])

  useEffect(() => {
    if (!isPreview) {
      if (viewMode) {
        // modal is not shown, so we should set the state automatically
        setRendererState(prevState => ({
          ...prevState,
          hasStarted: viewMode === 'start',
          hasAskedForStartLesson: true,
          viewMode
        }))
      } else {
        // If not set ensure modal is shown
        setShowModalStart(true)
      }
    }
  }, [viewMode, isPreview])

  if (!currentSlide) {
    return (
      <>
        <Placeholder>
          Screen not found
        </Placeholder>
        <Nav />
      </>
    )
  }

  const SlideComponent = getSlideTypeComponent(currentSlide.type)

  return (
    <div
      ref={deckRef}
      style={{ background: 'inherit' }}
    >
      {!viewMode && !isPreview && showModalStart && !project.disableModalStart && (
        <ModalStart
          onHide={hideModalStart}
          deckId={currentDeckId}
        />
      )}
      <SlideComponent
        slide={currentSlide}
        deck={currentDeck}
        isEditable={isEditable}
        onAreaClick={onAreaClick}
        selectedAreaId={selectedAreaId}
        logo={project.overview.logo}
      />
      <Nav />
    </div>
  )
}

export default Slide
