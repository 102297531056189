const STORAGE_KEY = 'edg-pidk-view-mode'

export const setViewModeInStorage = (viewMode: string) => {
  sessionStorage.setItem(STORAGE_KEY, viewMode)
}

export const getViewModeFromStorage = (): string => {
  return sessionStorage.getItem(STORAGE_KEY)
}

export const removeViewModeFromStorage = () => {
  sessionStorage.removeItem(STORAGE_KEY)
}
